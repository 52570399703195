@charset "UTF-8";
body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
}

.formContainer {
  padding: 0 20px;
  max-width: 940px;
  margin: 60px auto 180px auto;
}

@media (max-width: 940px) {
  body {
    max-width: unset;
  }
}
@media (max-width: 700px) {
  .formContainer {
    margin: 60px auto 120px auto;
  }
}


form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

input,
select {
  border-radius: 7px;
  border: 1px solid #dddddd;
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
  padding-left: 15px;
  height: 36px;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.MuiInputBase-root-MuiOutlinedInput-root,
.date-input {
  border-radius: 7px;
  border: 1px solid #dddddd;
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
  padding-left: 15px;
  height: 42px;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiDateCalendar-root {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  width: 320px;
  max-height: 358px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: white;
  border: 2px solid #67cee3;
  border-radius: 15px;
  transform: translate(4px, 4px);
}

input::-webkit-input-placeholder {
  color: #0a387d;
}

textarea::-webkit-input-placeholder {
  color: #0a387d;
}

input:focus,
select:focus,
textarea:focus {
  border: 2px solid #abc941;
  outline: none !important;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0.3;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0.3;
}

input:focus::-ms-input-placeholder,
textarea:focus::-ms-input-placeholder {
  opacity: 0.3;
}

input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0.3;
}

p {
  color: #707070;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  line-height: 26px;
  color: #0a387d;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
}

@media (max-width: 700px) {
  h2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 20px;
    position: relative;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media (max-width: 700px) {
  h2:after {
    position: absolute;
    width: 100% !important;
    bottom: 0;
  }
}

textarea {
  width: 96%;
  height: 230px;
  border: 1px solid #dddddd;
  border-radius: 7px;
  padding: 15px;
  resize: none;
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  textarea {
    width: 90%;
  }
}

fieldset {
  padding: 0;
  width: 100%;
}

.introducao {
  margin-bottom: 45px;
}

p.margin {
  margin-left: 45px;
}

.introducao .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 4px solid #abc941;
  width: 100%;
  svg {
    min-width: 126px;
  }
}

@media (max-width: 550px) {
  .introducao .title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.introducao .title h1 {
  max-width: 420px;
  font-size: 30px;
  line-height: 33px;
  color: #0a387d;
  margin: 0;
}

@media (max-width: 700px) {
  .introducao .title h1 {
    font-size: 28px;
    line-height: 31px;
  }
}

@media (max-width: 550px) {
  .introducao .title a {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-bottom: 50px;
  }
}

.introducao p,
.introducao span {
  color: #707070;
  /* margin-top: 85px; */
}

.introducao p:last-of-type {
  color: #c36a72;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
}

.introducao span {
  font-weight: bold;
  color: #0a387d;
  margin: 0;
}

#blue-line {
  width: 175px;
  height: 7px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2e90ce),
    to(#6fd6e6)
  );
  background-image: linear-gradient(to right, #2e90ce, #6fd6e6);
  margin-top: 5px;
}

#blue-line2 {
  width: 80px;
  height: 3px;
  background-color: #0a387d;
  margin-top: 3px;
  margin-bottom: 45px;
}

.dados-do-solicitante {
  border: none;
  padding: 0;
}

.dados-do-solicitante h2:after {
  content: "";
  width: 65%;
  height: 4px;
  background-color: #abc941;
  flex: 1;
  margin-left: 25px;
}

.dados-do-solicitante h2 span {
  font-size: 12px;
  margin: 0 0.5rem;
  color: #c36a72;
  line-height: 1;
}
.dados-do-solicitante h3 span {
  font-size: 12px;
  margin: 0 0.5rem;
  color: #c36a72;
  line-height: 1;
}

.dados-do-solicitante .observation {
  color: #c36a72;
  font-weight: bold;
  font-size: 14px;
  margin: 1rem 0;
}

.dados-do-solicitante .inputs {
  display: -ms-grid;
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 10px;
}

@media (max-width: 700px) {
  .dados-do-solicitante .inputs {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }
}

.dados-do-solicitante .inputs .entirerow {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / span 2;
}

@media (max-width: 700px) {
  .dados-do-solicitante .inputs .entirerow {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
  }
}

h3:after {
  content: "";
  width: 65%;
  height: 2px;
  background-color: #67cee3;
  flex: 1;
  margin-left: 25px;
}

.dados-do-solicitante .representante:after {
  width: 59%;
}

.dados-do-solicitante .projeto:after {
  width: 73%;
}

.dados-do-solicitante .sem-lucrativos {
  margin-top: 45px;
}

.dados-do-solicitante .fins-lucrativos > div,
.dados-do-solicitante .sem-lucrativos > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 25px 0;
}

.dados-do-solicitante .proposta-iniciativa > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 25px 0;
}

.dados-do-solicitante .fins-lucrativos > div input[type="radio"],
.dados-do-solicitante .sem-lucrativos > div input[type="radio"] {
  display: none;
  /* Esconde os inputs */
}

.dados-do-solicitante .proposta-iniciativa > div input[type="radio"] {
  max-width: 1px;
}

.dados-do-solicitante .fins-lucrativos > div input[type="radio"] + label:before,
.dados-do-solicitante .sem-lucrativos > div input[type="radio"] + label:before {
  border: 1px solid #5d5c5c;
  content: "\00a0";
  display: inline-block;
  font: 16px sans-serif;
  height: 25px;
  margin: 0 10px 0 0;
  padding: 0;
  vertical-align: top;
  width: 25px;
  border-radius: 7px;
}

.dados-do-solicitante
  .proposta-iniciativa
  > div
  input[type="radio"]
  + label:before {
  border: 1px solid #5d5c5c;
  content: "\00a0";
  display: inline-block;
  font: 16px sans-serif;
  height: 25px;
  margin: 0 10px 0 0;
  padding: 0;
  vertical-align: top;
  width: 25px;
  border-radius: 7px;
}

.dados-do-solicitante
  .fins-lucrativos
  > div
  input[type="radio"]:checked
  + label:before,
.dados-do-solicitante
  .sem-lucrativos
  > div
  input[type="radio"]:checked
  + label:before {
  background: #fff;
  color: #fff;
  content: "";
  text-align: center;
  background-image: url("../../img/check.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
}

.dados-do-solicitante
  .proposta-iniciativa
  > div
  input[type="radio"]:checked
  + label:before {
  background: #fff;
  color: #fff;
  content: "";
  text-align: center;
  background-image: url("../../img/check.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
}

.dados-do-solicitante .proposta-iniciativa>div input[type="checkbox"]:checked+label:before {
  background: #fff;
  color: #fff;
  content: "";
  text-align: center;
  background-image: url("../../img/check.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
}

.dados-do-solicitante
  .fins-lucrativos
  > div
  input[type="radio"]:checked
  + label:after,
.dados-do-solicitante
  .sem-lucrativos
  > div
  input[type="radio"]:checked
  + label:after {
  font-weight: bold;
}

.dados-do-solicitante
  .proposta-iniciativa
  > div
  input[type="radio"]:checked
  + label:after {
  font-weight: bold;
}

.dados-do-solicitante .fins-lucrativos > div label,
.dados-do-solicitante .sem-lucrativos > div label {
  font-size: 16px;
  color: #707070;
  line-height: 26px;
  cursor: pointer;
}

.dados-do-solicitante .proposta-iniciativa > div label {
  font-size: 16px;
  color: #707070;
  line-height: 26px;
  cursor: pointer;
}

.dados-do-solicitante .fins-lucrativos .tipo,
.dados-do-solicitante .sem-lucrativos .tipo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dados-do-solicitante .proposta-iniciativa .tipo {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.dados-do-solicitante .proposta-iniciativa .tipo > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dados-do-solicitante .fins-lucrativos .tipo > div,
.dados-do-solicitante .sem-lucrativos .tipo > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dados-do-solicitante .fins-lucrativos .tipo label,
.dados-do-solicitante .sem-lucrativos .tipo label {
  margin-right: 40px;
}

.dados-do-solicitante .proposta-iniciativa .tipo label {
  margin-right: 40px;
}

.section {
  margin-top: 60px;
  padding: 0;
}

.section h3 {
  font-size: 20px;
  color: #0a387d;
  line-height: 24px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 700px) {
  .section h3 {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 10px;
  }
}

@media (max-width: 700px) {
  .section h3:after {
    position: absolute;
    width: 100% !important;
    bottom: 0;
  }
}

.apresentacao {
  border: none;
  margin-top: 60px;
  padding: 0;
}

.apresentacao h2:after {
  width: 32%;
  content: "";
  height: 4px;
  background-color: #abc941;
}

.apresentacao .anexos {
  font-size: 16px;
  line-height: 26px;
  color: #0a387d;
  margin: 20px 0;
}

.apresentacao #arquivo-uploader {
    display: none;
    }
.apresentacao #arquivo-uploader2 {
  display: none;
}

.apresentacao .escolher-arquivo {
  border-radius: 7px;
  border: 1px solid #dddddd;
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
  padding: 10px;
  cursor: pointer;
}

.apresentacao .escolher-arquivo:hover {
  border: 2px solid #abc941;
}

.nome-do-projeto {
  border: none;
  margin-top: 60px;
}

.nome-do-projeto h2:after {
  width: 70%;
  content: "";
  height: 4px;
  background-color: #abc941;
}

.nome-do-projeto .inputs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: '(48.5%) [2]';
  grid-template-columns: repeat(2, 48.5%);
  grid-column-gap: 25px;
  grid-row-gap: 10px;
  margin-top: 12px;
}

@media (max-width: 700px) {
  .nome-do-projeto .inputs {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }
}

.nome-do-projeto .inputs .input {
  width: 100%;
}

.nome-do-projeto .inputs .entirerow {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / span 2;
}

@media (max-width: 700px) {
  .nome-do-projeto .inputs .entirerow {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
  }
}

.tema-do-projeto {
  border: none;
  margin-top: 60px;
  padding: 0;
}

.tema-do-projeto h2:after {
  width: 78%;
  content: "";
  height: 4px;
  background-color: #abc941;
}

.tema-do-projeto .opcoes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tema-do-projeto .opcoes > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tema-do-projeto .opcoes input[type="radio"] {
  max-width: 1px;
}

.tema-do-projeto .opcoes input[type="radio"] + label:before {
  border: 1px solid #5d5c5c;
  content: "\00a0";
  display: inline-block;
  font: 16px sans-serif;
  height: 25px;
  margin: 0 10px 0 0;
  padding: 0;
  vertical-align: top;
  width: 25px;
  min-width: 25px;
  border-radius: 7px;
}

.tema-do-projeto .opcoes input[type="radio"]:checked + label:before {
  background: #fff;
  color: #fff;
  content: "";
  text-align: center;
  background-image: url("../../img/check.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
}

.tema-do-projeto .opcoes input[type="radio"]:checked + label:after {
  font-weight: bold;
}

.tema-do-projeto .opcoes label {
  font-size: 16px;
  color: #707070;
  line-height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tema-do-projeto textarea {
  margin-top: -15px;
}

.tema-do-projeto .anexos {
  font-size: 16px;
  line-height: 26px;
  color: #0a387d;
  margin: 20px 0;
}

.tema-do-projeto #tema-uploader {
  display: none;
}

.tema-do-projeto .escolher-arquivo {
  border-radius: 7px;
  border: 1px solid #dddddd;
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
  padding: 10px;
  cursor: pointer;
}

.tema-do-projeto .escolher-arquivo:hover {
  border: 2px solid #abc941;
}

.apresentacao-do-projeto {
  border: none;
  margin-top: 60px;
  padding: 0;
}

.apresentacao-do-projeto h2:after {
  width: 35%;
  content: "";
  height: 4px;
  background-color: #abc941;
}

.apresentacao-do-projeto ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.apresentacao-do-projeto ul li {
  color: #707070;
  font-size: 16px;
  line-height: 26px;
}

.apresentacao-do-projeto textarea {
  margin-top: -15px;
}

.divulgacao {
  border: none;
  margin-top: 60px;
  padding: 0;
}

.divulgacao h2:after {
  width: 85%;
  content: "";
  height: 4px;
  background-color: #abc941;
}

.divulgacao .meios-de-divulgação {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: '(33%) [2]';
  grid-template-columns: repeat(2, 33%);
  grid-column-gap: 60px;
  grid-row-gap: 15px;
  margin-bottom: 30px;
}

@media (max-width: 700px) {
  .divulgacao .meios-de-divulgação {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }
}

.divulgacao .meios-de-divulgação > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.divulgacao .meios-de-divulgação > div input[type="checkbox"] {
  display: none;
  /* Esconde os inputs */
}

.divulgacao .meios-de-divulgação > div input[type="checkbox"] + label:before {
  border: 1px solid #5d5c5c;
  content: "\00a0";
  display: inline-block;
  font: 16px sans-serif;
  height: 25px;
  margin: 0 10px 0 0;
  padding: 0;
  vertical-align: top;
  width: 25px;
  border-radius: 7px;
}

.divulgacao
  .meios-de-divulgação
  > div
  input[type="checkbox"]:checked
  + label:before {
  background: #fff;
  color: #fff;
  content: "";
  text-align: center;
  background-image: url("../../img/check.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
}

.divulgacao
  .meios-de-divulgação
  > div
  input[type="checkbox"]:checked
  + label:after {
  font-weight: bold;
}

.divulgacao .meios-de-divulgação > div label {
  font-size: 16px;
  color: #707070;
  line-height: 26px;
  cursor: pointer;
}

.contrapartida {
  margin-top: 60px;
  border: none;
  padding: 0;
}

.contrapartida h2:after {
  width: 80%;
  content: "";
  height: 4px;
  background-color: #abc941;
}

.contrapartida .section {
  margin-top: 20px;
}

.contrapartida .section h3:after {
  content: "";
  height: 2px;
  background-color: #67cee3;
}

.contrapartida .section .tv:after {
  width: 87%;
}

.contrapartida .section .jornal:after {
  width: 83%;
}

.contrapartida .section .midia:after {
  width: 42%;
}

.contrapartida .section .pecas:after {
  width: 85%;
}

.contrapartida .section .outros:after {
  width: 56%;
}

.contrapartida .section .outros-itens:after {
  width: 72%;
}

@media (max-width: 700px) {
  .contrapartida .section .nodisplay {
    display: none;
  }
}

.contrapartida .section .display {
  display: none;
  width: 100%;
}

.contrapartida .section .display .line {
  margin: 10px 0;
  width: 50%;
  height: 2px;
  background-color: #abc941;
}

@media (max-width: 700px) {
  .contrapartida .section .display {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 0px;
  }
  .contrapartida .section .display input {
    width: 96%;
    margin-bottom: 10px;
  }
}

.contrapartida .section > div {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: '45% (24%) [2]';
  grid-template-columns: 45% repeat(2, 24%);
  grid-column-gap: 25px;
  grid-row-gap: 10px;
}

@media (max-width: 700px) {
  .contrapartida .section > div {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }
}

.contrapartida .section > div label {
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
}

.mensagem {
  width: 100%;
  padding-top: 25px;
  border-top: 2px solid #67cee3;
  margin-top: 25px;
}

.mensagem input {
  width: 98%;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .mensagem input {
    width: 92%;
  }
}

.declaracoes {
  margin-top: 40px;
}

.declaracoes > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.declaracoes > div p {
  margin: 0;
}

.declaracoes > div input[type="checkbox"] {
  display: none;
  /* Esconde os inputs */
}

.declaracoes > div input[type="checkbox"] + label:before {
  border: 1px solid #5d5c5c;
  content: "\00a0";
  display: inline-block;
  font: 16px sans-serif;
  height: 25px;
  margin: 0 10px 0 0;
  padding: 0;
  vertical-align: top;
  width: 25px;
  border-radius: 7px;
}

.declaracoes > div input[type="checkbox"]:checked + label:before {
  background: #fff;
  color: #fff;
  content: "";
  text-align: center;
  background-image: url("../../img/check.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
}

.declaracoes > div input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

.declaracoes > div label {
  font-size: 16px;
  color: #707070;
  line-height: 26px;
  cursor: pointer;
}

.documentacoes {
  margin-top: 40px;
}

.documentacoes h2:after {
  width: 30%;
  content: "";
  height: 4px;
  background-color: #abc941;
}

.documentacoes ul {
  padding-left: 20px;
  margin-bottom: 30px;
}

.documentacoes ul li {
  font-size: 16px;
  line-height: 26px;
  color: #0a387d;
  font-weight: bold;
}

.enviar {
  font-size: 18px;
  color: #0a387d;
  line-height: 24px;
  font-weight: bold;
  padding: 15px 20px;
  border-radius: 7px;
  background-color: #abc941;
  border: 2px solid #abc941;
  margin-top: 40px;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.enviar:hover {
  background-color: #fff;
  border: 2px solid #0a387d;
}

.enviar:disabled {
  opacity: 0.5;
  background: #ccc;
  border-color: #ccc;
  color: #111;
}

.error {
  border-color: red !important;
}
.bold {
  font-weight: bold;
}

.error-message {
  color: red;
  padding: 5px 0;
  font-size: 15px;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #504c4c6a;
  svg {
      width: 126px;
    }
}

.modal-content {
  width: 550px;
  height: max-content;
  margin: auto;
  background: white;
  border-radius: 50px;
  padding: 50px;
  font-size: 22px;
  text-align: center;
  color: #0a387d;
}

.modal-message {
  margin-top: 25px;
}

.close {
  float: right;
}

.oficio {
    font-size: 20px;
    color: #0a387d;
    line-height: 24px;
    font-weight: bold;
}

.nome-do-projeto span {
  font-size: 15px;
  margin-bottom: 30px;
}