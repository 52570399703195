@charset "UTF-8";
/*# sourceMappingURL=style.css.map */
body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  max-width: 940px;
  margin: 120px auto 180px auto;
  padding: 0 20px;
}
form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
input {
  border-radius: 7px;
  border: 1px solid #dddddd;
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
  padding-left: 15px;
  height: 42px;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  &::-webkit-input-placeholder {
    color: #0a387d;
  }
  &:focus {
    border: 2px solid #abc941;
    outline: none !important;
    &::-webkit-input-placeholder {
      opacity: 0.3;
    }
    &:-ms-input-placeholder {
      opacity: 0.3;
    }
    &::-ms-input-placeholder {
      opacity: 0.3;
    }
    &::placeholder {
      opacity: 0.3;
    }
  }
}
select {
  border-radius: 7px;
  border: 1px solid #dddddd;
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
  padding-left: 15px;
  height: 42px;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  &:focus {
    border: 2px solid #abc941;
    outline: none !important;
  }
}
textarea {
  &::-webkit-input-placeholder {
    color: #0a387d;
  }
  &:focus {
    border: 2px solid #abc941;
    outline: none !important;
    &::-webkit-input-placeholder {
      opacity: 0.3;
    }
    &:-ms-input-placeholder {
      opacity: 0.3;
    }
    &::-ms-input-placeholder {
      opacity: 0.3;
    }
    &::placeholder {
      opacity: 0.3;
    }
  }
  width: 96%;
  height: 230px;
  border: 1px solid #dddddd;
  border-radius: 7px;
  padding: 15px;
  resize: none;
  font-size: 16px;
  color: #0a387d;
  line-height: 26px;
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
p {
  color: #707070;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
}
h2 {
  font-size: 20px;
  line-height: 26px;
  color: #0a387d;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
fieldset {
  padding: 0;
  width: 100%;
}
.introducao {
  margin-bottom: 45px;
  .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 4px solid #abc941;
    width: 100%;
    h1 {
      max-width: 420px;
      font-size: 30px;
      line-height: 33px;
      color: #0a387d;
      margin: 0;
    }
  }
  p {
    color: #707070;
    &:last-of-type {
      color: #c36a72;
      font-weight: bold;
    }
  }
  span {
    color: #707070;
    font-weight: bold;
    color: #0a387d;
    margin: 0;
  }
}
#blue-line {
  width: 175px;
  height: 7px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2e90ce),
    to(#6fd6e6)
  );
  background-image: linear-gradient(to right, #2e90ce, #6fd6e6);
  margin-top: 5px;
}
#blue-line2 {
  width: 80px;
  height: 3px;
  background-color: #0a387d;
  margin-top: 3px;
  margin-bottom: 85px;
}
.dados-do-solicitante {
  border: none;
  margin-top: 60px;
  padding: 0;
  h2 {
    &:after {
      content: "";
      width: 70%;
      height: 4px;
      background-color: #abc941;
    }
    span {
      font-size: 12px;
      margin: 0 0.5rem;
      color: #c36a72;
      line-height: 1;
    }
  }
  h3 {
    span {
      font-size: 12px;
      margin: 0 0.5rem;
      color: #c36a72;
      line-height: 1;
    }
    &:after {
      content: "";
      width: 80%;
      height: 2px;
      background-color: #67cee3;
    }
  }
  .observation {
    color: #c36a72;
    font-weight: bold;
    font-size: 14px;
    margin: 1rem 0;
  }
  .inputs {
    display: -ms-grid;
    display: grid;
    grid-column-gap: 25px;
    grid-row-gap: 10px;
    margin-bottom: 5rem;
    .entirerow {
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      grid-column: 1 / span 2;
    }
  }
  .representante {
    &:after {
      width: 59%;
    }
  }
  .projeto {
    &:after {
      width: 73%;
    }
  }
  .sem-lucrativos {
    margin-top: 45px;
    > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 25px 0;
      input[type="radio"] {
        display: none;
        + {
          label {
            &:before {
              border: 1px solid #5d5c5c;
              content: "\00a0";
              display: inline-block;
              font: 16px sans-serif;
              height: 25px;
              margin: 0 10px 0 0;
              padding: 0;
              vertical-align: top;
              width: 25px;
              border-radius: 7px;
            }
          }
        }
        &:checked {
          + {
            label {
              &:before {
                background: #fff;
                color: #fff;
                content: "";
                text-align: center;
                background-image: url("../img/check.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 20px auto;
              }
              &:after {
                font-weight: bold;
              }
            }
          }
        }
      }
      label {
        font-size: 16px;
        color: #707070;
        line-height: 26px;
        cursor: pointer;
      }
    }
    .tipo {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      > div {
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      label {
        margin-right: 40px;
      }
    }
  }
  .fins-lucrativos {
    > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 25px 0;
      input[type="radio"] {
        display: none;
        + {
          label {
            &:before {
              border: 1px solid #5d5c5c;
              content: "\00a0";
              display: inline-block;
              font: 16px sans-serif;
              height: 25px;
              margin: 0 10px 0 0;
              padding: 0;
              vertical-align: top;
              width: 25px;
              border-radius: 7px;
            }
          }
        }
        &:checked {
          + {
            label {
              &:before {
                background: #fff;
                color: #fff;
                content: "";
                text-align: center;
                background-image: url("../img/check.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 20px auto;
              }
              &:after {
                font-weight: bold;
              }
            }
          }
        }
      }
      label {
        font-size: 16px;
        color: #707070;
        line-height: 26px;
        cursor: pointer;
      }
    }
    .tipo {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      > div {
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      label {
        margin-right: 40px;
      }
    }
  }
  .proposta-iniciativa {
    > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 25px 0;
      input[type="radio"] {
        display: none;
        + {
          label {
            &:before {
              border: 1px solid #5d5c5c;
              content: "\00a0";
              display: inline-block;
              font: 16px sans-serif;
              height: 25px;
              margin: 0 10px 0 0;
              padding: 0;
              vertical-align: top;
              width: 25px;
              border-radius: 7px;
            }
          }
        }
        &:checked {
          + {
            label {
              &:before {
                background: #fff;
                color: #fff;
                content: "";
                text-align: center;
                background-image: url("../img/check.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 20px auto;
              }
              &:after {
                font-weight: bold;
              }
            }
          }
        }
      }
      label {
        font-size: 16px;
        color: #707070;
        line-height: 26px;
        cursor: pointer;
      }
    }
    .tipo {
      display: flex;
      flex-direction: column;
      align-items: start;
      > div {
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      label {
        margin-right: 40px;
      }
    }
  }
}
.section {
  margin-top: 60px;
  padding: 0;
  h3 {
    font-size: 20px;
    color: #0a387d;
    line-height: 24px;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;
  }
}
.apresentacao {
  border: none;
  margin-top: 60px;
  padding: 0;
  h2 {
    &:after {
      width: 32%;
      content: "";
      height: 4px;
      background-color: #abc941;
    }
  }
  .anexos {
    font-size: 16px;
    line-height: 26px;
    color: #0a387d;
    margin: 20px 0;
  }
  #arquivo-uploader {
    display: none;
  }
  .escolher-arquivo {
    border-radius: 7px;
    border: 1px solid #dddddd;
    font-size: 16px;
    color: #0a387d;
    line-height: 26px;
    padding: 10px;
    cursor: pointer;
    &:hover {
      border: 2px solid #abc941;
    }
  }
}
.nome-do-projeto {
  border: none;
  margin-top: 60px;
  h2 {
    &:after {
      width: 70%;
      content: "";
      height: 4px;
      background-color: #abc941;
    }
  }
  .inputs {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: "(48.5%) [2]";
    grid-template-columns: repeat(2, 48.5%);
    grid-column-gap: 25px;
    grid-row-gap: 10px;
    .input {
      width: 100%;
    }
    .entirerow {
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      grid-column: 1 / span 2;
    }
  }
}
.tema-do-projeto {
  border: none;
  margin-top: 60px;
  padding: 0;
  h2 {
    &:after {
      width: 78%;
      content: "";
      height: 4px;
      background-color: #abc941;
    }
  }
  .opcoes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: -16px;
    > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 11px 0;
    }
    input[type="radio"] {
      display: none;
      + {
        label {
          &:before {
            border: 1px solid #5d5c5c;
            content: "\00a0";
            display: inline-block;
            font: 16px sans-serif;
            height: 25px;
            margin: 0 10px 0 0;
            padding: 0;
            vertical-align: top;
            min-width:25px;
            width: 25px;
            border-radius: 7px;
          }
        }
      }
      &:checked {
        + {
          label {
            &:before {
              background: #fff;
              color: #fff;
              content: "";
              text-align: center;
              background-image: url("../img/check.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 20px auto;
            }
            &:after {
              font-weight: bold;
            }
          }
        }
      }
    }
    label {
      font-size: 16px;
      color: #707070;
      line-height: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
  textarea {
    margin-top: -15px;
  }
  .anexos {
    font-size: 16px;
    line-height: 26px;
    color: #0a387d;
    margin: 20px 0;
  }
  #tema-uploader {
    display: none;
  }
  .escolher-arquivo {
    border-radius: 7px;
    border: 1px solid #dddddd;
    font-size: 16px;
    color: #0a387d;
    line-height: 26px;
    padding: 10px;
    cursor: pointer;
    &:hover {
      border: 2px solid #abc941;
    }
  }
}
.apresentacao-do-projeto {
  border: none;
  margin-top: 60px;
  padding: 0;
  h2 {
    &:after {
      width: 35%;
      content: "";
      height: 4px;
      background-color: #abc941;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      color: #707070;
      font-size: 16px;
      line-height: 26px;
    }
  }
  textarea {
    margin-top: -15px;
  }
}
.divulgacao {
  border: none;
  margin-top: 60px;
  padding: 0;
  h2 {
    &:after {
      width: 85%;
      content: "";
      height: 4px;
      background-color: #abc941;
    }
  }
  .meios-de-divulgação {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: "(33%) [2]";
    grid-template-columns: repeat(2, 33%);
    grid-column-gap: 60px;
    grid-row-gap: 15px;
    margin-bottom: 30px;
    > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      input[type="checkbox"] {
        display: none;
        + {
          label {
            &:before {
              border: 1px solid #5d5c5c;
              content: "\00a0";
              display: inline-block;
              font: 16px sans-serif;
              height: 25px;
              margin: 0 10px 0 0;
              padding: 0;
              vertical-align: top;
              width: 25px;
              border-radius: 7px;
            }
          }
        }
        &:checked {
          + {
            label {
              &:before {
                background: #fff;
                color: #fff;
                content: "";
                text-align: center;
                background-image: url("../img/check.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 20px auto;
              }
              &:after {
                font-weight: bold;
              }
            }
          }
        }
      }
      label {
        font-size: 16px;
        color: #707070;
        line-height: 26px;
        cursor: pointer;
      }
    }
  }
}
.contrapartida {
  margin-top: 60px;
  border: none;
  padding: 0;
  h2 {
    &:after {
      width: 80%;
      content: "";
      height: 4px;
      background-color: #abc941;
    }
  }
  .section {
    margin-top: 20px;
    h3 {
      &:after {
        content: "";
        height: 2px;
        background-color: #67cee3;
      }
    }
    .tv {
      &:after {
        width: 87%;
      }
    }
    .jornal {
      &:after {
        width: 83%;
      }
    }
    .midia {
      &:after {
        width: 42%;
      }
    }
    .pecas {
      &:after {
        width: 85%;
      }
    }
    .outros {
      &:after {
        width: 56%;
      }
    }
    .outros-itens {
      &:after {
        width: 72%;
      }
    }
    .display {
      display: none;
      width: 100%;
      .line {
        margin: 10px 0;
        width: 50%;
        height: 2px;
        background-color: #abc941;
      }
    }
    > div {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: "45% (24%) [2]";
      grid-template-columns: 45% repeat(2, 24%);
      grid-column-gap: 25px;
      grid-row-gap: 10px;
      label {
        font-size: 16px;
        color: #0a387d;
        line-height: 26px;
      }
    }
  }
}
.mensagem {
  width: 100%;
  padding-top: 25px;
  border-top: 2px solid #67cee3;
  margin-top: 25px;
  input {
    width: 98%;
    margin-bottom: 10px;
  }
}
.declaracoes {
  margin-top: 40px;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    p {
      margin: 0;
    }
    input[type="checkbox"] {
      display: none;
      + {
        label {
          &:before {
            border: 1px solid #5d5c5c;
            content: "\00a0";
            display: inline-block;
            font: 16px sans-serif;
            height: 25px;
            margin: 0 10px 0 0;
            padding: 0;
            vertical-align: top;
            width: 25px;
            border-radius: 7px;
          }
        }
      }
      &:checked {
        + {
          label {
            &:before {
              background: #fff;
              color: #fff;
              content: "";
              text-align: center;
              background-image: url("../img/check.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 20px auto;
            }
            &:after {
              font-weight: bold;
            }
          }
        }
      }
    }
    label {
      font-size: 16px;
      color: #707070;
      line-height: 26px;
      cursor: pointer;
    }
  }
}
.documentacoes {
  margin-top: 40px;
  h2 {
    &:after {
      width: 30%;
      content: "";
      height: 4px;
      background-color: #abc941;
    }
  }
  ul {
    padding-left: 20px;
    margin-bottom: 30px;
    li {
      font-size: 16px;
      line-height: 26px;
      color: #0a387d;
      font-weight: bold;
    }
  }
}
.enviar {
  font-size: 18px;
  color: #0a387d;
  line-height: 24px;
  font-weight: bold;
  padding: 15px 20px;
  border-radius: 7px;
  background-color: #abc941;
  border: 2px solid #abc941;
  margin-top: 40px;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  &:hover {
    background-color: #fff;
    border: 2px solid #0a387d;
  }
  &:disabled {
    opacity: 0.5;
    background: #ccc;
    border-color: #ccc;
    color: #111;
  }
}
.error {
  border-color: red !important;
}
.error-message {
  color: red;
  padding: 5px 0;
  font-size: 15px;
}
@media (max-width: 940px) {
  body {
    max-width: unset;
  }
}
@media (max-width: 700px) {
  body {
    margin: 60px auto 120px auto;
  }
  h2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 20px;
    position: relative;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    &:after {
      position: absolute;
      width: 100% !important;
      bottom: 0;
    }
  }
  textarea {
    width: 90%;
  }
  .introducao {
    .title {
      h1 {
        font-size: 28px;
        line-height: 31px;
      }
    }
  }
  .dados-do-solicitante {
    .inputs {
      -ms-grid-columns: 100%;
      grid-template-columns: 100%;
      .entirerow {
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
        grid-column: 1 / span 1;
      }
    }
  }
  .section {
    h3 {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-bottom: 10px;
      &:after {
        position: absolute;
        width: 100% !important;
        bottom: 0;
      }
    }
  }
  .nome-do-projeto {
    .inputs {
      -ms-grid-columns: 100%;
      grid-template-columns: 100%;
      .entirerow {
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
        grid-column: 1 / span 1;
      }
    }
  }
  .divulgacao {
    .meios-de-divulgação {
      -ms-grid-columns: 100%;
      grid-template-columns: 100%;
    }
  }
  .contrapartida {
    .section {
      .nodisplay {
        display: none;
      }
      .display {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        grid-row-gap: 0px;
        input {
          width: 96%;
          margin-bottom: 10px;
        }
      }
      > div {
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;
      }
    }
  }
  .mensagem {
    input {
      width: 92%;
    }
  }
}
@media (max-width: 550px) {
  .introducao {
    .title {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      a {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        margin-bottom: 50px;
      }
    }
  }
}
